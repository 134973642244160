<template>
  <div class="turnouts">

    <!-- Banner -->
    <banner
      :image="data.bannerImage"
      :video="data.bannerTurnoutsVideo"
      :title="$t('turnouts.bannerTitle')"
      height="600"
    />

    <maintenance />

    <provide />

  </div>
</template>

<script>

import Banner from 'Components/Common/Banner.vue'
import Maintenance from 'Components/Turnouts/Maintenance.vue'
import Provide from 'Components/Turnouts/Provide.vue'
import MyData from 'Data/imas.json'

export default {
  name: 'Turnouts',
  components: {
    Banner,
    Maintenance,
    Provide,
  },
  data() {
    return {
      data: MyData.data,
    }
  },
}
</script>
