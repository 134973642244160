<template>
  <div class="mt-5 container">

    <!-- Title -->
    <h1 class="text-center mb-4">
      {{ $t('turnouts.provideTitle') }}
    </h1>

    <!-- Subtitle -->
    <p class="mb-5">
      {{ $t('turnouts.provideSubtitle') }}
    </p>

    <!-- Image -->
    <div class="text-center">
      <b-img-lazy
        src="/static/limmat-images/turnouts/esquema.png"
        alt="Anomalies Image"
        class="anomalies"
      />
    </div>
  </div>
</template>

<script>

import { BImgLazy } from 'bootstrap-vue'

export default {
  name: 'Provide',
  components: {
    BImgLazy,
  },
}
</script>

<style scoped>

  h3 {
    font-size: 35px;
  }

  h5 {
    font-size: 55px;
    color: black;
  }

  span {
    color: #ff8f00;
    font-size: 20px;
  }

  .anomalies {
    width: 40%;
  }

  @media(max-width:992px) {
    .anomalies {
      width: 80%;
    }
  }
</style>
