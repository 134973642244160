<template>
  <div class="mt-5 container">

    <!-- Title -->
    <h1 class="display-2 text-center mb-4">
      {{ $t('turnouts.maintenanceTitle') }}
    </h1>

    <!-- Subitle -->
    <p class="text-center mb-4">
      {{ $t('turnouts.maintenanceSubtitle') }}
    </p>

    <!-- Percentage -->
    <b-row class="text-center my-5">
      <b-col cols="6">
        <h5>
          15%
        </h5>

        <span>
          {{ $t('turnouts.budget1') }}
        </span>
      </b-col>

      <b-col cols="6">
        <h5>
          25%
        </h5>

        <span>
          {{ $t('turnouts.budget2') }}
        </span>
      </b-col>
    </b-row>

    <!-- Text -->
    <p>
      {{ $t('turnouts.subtitle') }}
    </p>

    <!-- Frecuencies -->
    <b-row class="text-center my-5">
      <b-col
        lg="6"
        md="12"
        class="mb-5"
      >
        <h3 class="mb-3">
          {{ $t('turnouts.frequency') }}
        </h3>

        <b-img-lazy
          src="/static/limmat-images/turnouts/frecuenciaFallo.png"
          alt="Frequency Image"
          style="width: 100%"
          class="imgLazy"
        />
      </b-col>

      <b-col
        lg="6"
        md="12"
      >
        <h3 class="mb-3">
          {{ $t('turnouts.anomalies') }}
        </h3>

        <b-img-lazy
          src="/static/limmat-images/turnouts/anomalias.png"
          alt="Anomalies Image"
          style="width: 80%"
          class="imgLazy"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>

import {
  BRow,
  BCol,
  BImgLazy,
} from 'bootstrap-vue'

export default {
  name: 'Maintenance',
  components: {
    BRow,
    BCol,
    BImgLazy,
  },
}
</script>

<style scoped>

  h3 {
    font-size: 35px;
  }

  h5 {
    font-size: 55px;
    color: black;
  }

  span {
    color: #ff8f00;
    font-size: 20px;
  }

  .imgLazy {
      width: 100%;
    }

  @media (max-width: 700px){
    h3 {
      font-size: 20px;
    }

    h5 {
      font-size: 40px !important;
    }

    span {
      font-size: 15px !important;
    }
  }

  @media (min-width: 1200px) and (max-width: 1400px){
    .imgLazy {
      width: 80% !important;
    }
  }
</style>
